@font-face {
  font-family: 'FIRA-regular';
  src: url('fonts/FiraSans-Regular.ttf');
}

@font-face {
  font-family: 'LMSans-regular';
  src: url('fonts/LMSans-Regular.ttf');
}

@font-face {
  font-family: 'LMSerif-regular';
  src: url('fonts/LMSerif-Regular.ttf');
}

body {
  margin: 0;
  font-family: 'LMSans-regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'LMSerif-regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.field-row {
  display: flex;
  gap: 2rem
}

.field-row .field {
  flex-grow: 1;
}

.cow-dropdown {
  max-height: 40vh !important;
}

.green-icon {
  color: #006f30;
}
